import React from 'react';
import { Text, Spinner, Box } from '@chakra-ui/core';
import { Table, Thead, THeadRow, TH, TBodyRow, TD } from '../common/components/Table';
import { TableEditLink, TASKS_TYPES, TypeTag, TBody, THFilter } from './components';
import { getTaskRedirection } from '../common/routes';
import Can from '../roleBasedAccess/Can';
import { useTasksFilters } from './use-tasks-filters.hook';

export const AllTasksTab = ({
  tasks,
  tags: allTags,
  operators: allOperators,
  details: allDetails,
  pending,
}) => {
  const [
    filteredTasks,
    {
      currentDetails,
      currentOperators,
      currentTags,
      setCurrentDetails,
      setCurrentOperators,
      setCurrentTags,
    },
  ] = useTasksFilters(tasks);

  if (pending) {
    return (
      <Box color="semiDarkGray.600" mt={8} textAlign="center">
        <Spinner size={8} label="Chargement..." />
      </Box>
    );
  }

  if (!pending && tasks.length === 0)
    return (
      <Text color="semiDarkGray.600" mt={8} textAlign="center">
        Aucune tâche
      </Text>
    );

  return (
    <Table d="flex" flexDirection="column" px={8} h="100%">
      <Thead d="block" pos="sticky" zIndex="docked">
        <Can
          perform="tasks:operator:read"
          yes={({ children }) => (
            <THeadRow d="grid" gridTemplateColumns="100px 0.5fr 0.45fr 0.55fr 0.75fr 1fr 100px">
              {children}
            </THeadRow>
          )}
          no={({ children }) => (
            <THeadRow d="grid" gridTemplateColumns="100px 0.5fr 0.5fr 0.75fr 1fr 100px">
              {children}
            </THeadRow>
          )}
        >
          <TH>Dossier</TH>
          <TH>Bénéficiaire</TH>
          <TH>
            <THFilter
              options={allTags}
              label="Tag"
              menuOptionGroupOptions={{ onChange: setCurrentTags, value: currentTags }}
              isUpppercase
            />
          </TH>
          <Can
            perform="tasks:operator:read"
            yes={() => (
              <TH>
                <THFilter
                  options={allOperators}
                  label="Opérateurs"
                  menuOptionGroupOptions={{
                    onChange: setCurrentOperators,
                    value: currentOperators,
                  }}
                />
              </TH>
            )}
          />
          <TH>Type de tâche</TH>
          <TH>
            <THFilter
              options={allDetails}
              label="Détail"
              menuOptionGroupOptions={{
                onChange: setCurrentDetails,
                value: currentDetails,
              }}
              placement="bottom-center"
            />
          </TH>
          <TH>Délai</TH>
        </Can>
      </Thead>

      <TBody>
        {filteredTasks.map(
          ({
            id,
            folderId,
            folderFormattedId,
            recipientFirstName,
            recipientLastName,
            userFirstName,
            userLastName,
            tags,
            taskType,
            details,
            delay,
            ergoUserKind,
            ...task
          }) => {
            const taskLink = getTaskRedirection({ ...task, folderId });

            return (
              <Can
                key={id}
                perform="tasks:operator:read"
                yes={({ children }) => (
                  <TBodyRow
                    hasScaleOnHover={false}
                    d="grid"
                    gridTemplateColumns="100px 0.5fr 0.45fr 0.55fr 0.75fr 1fr 100px"
                  >
                    {children}
                  </TBodyRow>
                )}
                no={({ children }) => (
                  <TBodyRow
                    key={id}
                    hasScaleOnHover={false}
                    d="grid"
                    gridTemplateColumns="100px 0.5fr 0.5fr 0.75fr 1fr 100px"
                  >
                    {children}
                  </TBodyRow>
                )}
              >
                <TD>
                  <TableEditLink to={taskLink} py={2} whiteSpace="nowrap">
                    {folderFormattedId}
                    {ergoUserKind === 'externe' ? ' *' : null}
                  </TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={taskLink} py={2}>
                    {recipientFirstName} {recipientLastName.toUpperCase()}
                  </TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={taskLink} py={2} textTransform="uppercase">
                    {tags.join(', ')}
                  </TableEditLink>
                </TD>
                <Can
                  perform="tasks:operator:read"
                  yes={() => (
                    <TD>
                      <TableEditLink to={taskLink} py={2}>
                        {userFirstName} {userLastName.toUpperCase()}
                      </TableEditLink>
                    </TD>
                  )}
                />
                <TD>
                  <TableEditLink to={taskLink} py={2}>
                    <TypeTag
                      variantColor={Object.keys(TASKS_TYPES).find(
                        key => TASKS_TYPES[key] === taskType,
                      )}
                      fontWeight="normal"
                    >
                      {taskType}
                    </TypeTag>
                  </TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={taskLink} py={2}>
                    {details}
                  </TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={taskLink} py={2} whiteSpace="nowrap" fontWeight="bold">
                    J + {delay}
                  </TableEditLink>
                </TD>
              </Can>
            );
          },
        )}
      </TBody>
    </Table>
  );
};
