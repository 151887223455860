import React from 'react';
import { Switch, Route } from 'react-router';
import routes from '../common/routes';
import SectorsTabs from './SectorsTabs';

export const Sectors = () => {
  return (
    <Switch>
      <Route path={`${routes.sectors.index}/:sectorType`} component={SectorsTabs} />
      <Route path={routes.sectors.index} component={SectorsTabs} />
    </Switch>
  );
};
