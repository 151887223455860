import { apiV1 } from './api';
import { encodeQueryArray } from '../utils';

const getQualityControlSummaries = (page = 1, search) =>
  apiV1
    .get(
      `/quality_control_summaries?page=${page}&by_ergo_user=${search.ergoUser?.value || ''}&by_quality_controller_user=${search.qualityControllerUser?.value || ''}&by_keywords=${search.keywords || ''}${encodeQueryArray('by_tags', search.tags)}${encodeQueryArray('by_status', search.status)}${encodeQueryArray('by_indicators', search.indicators)}`,
    )
    .then(({ data }) => data);

const editQualityControlSummary = (qualityControlSummary) =>
  apiV1
    .put(`/quality_control_summaries/${qualityControlSummary.id}`, {
      qualityControlSummary,
    })
    .then(({ data }) => data);

export const qualityControlSummariesAPI = {
  getQualityControlSummaries,
  editQualityControlSummary,
};
