import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BaseTabs, TabList, Tab, TabWithBadge, TabPanels } from '../common/components/Tabs';
import routes, { getTasksPath } from '../common/routes';
import { history } from '../common/helpers/history';
import { PageTitle } from '../common/components/Titles';
import { AllTasksTab } from './AllTasksTab';
import { FilteredTasksTab } from './FilteredTasksTab';
import { TabPanel, TASKS_TYPES } from './components';
import { tasksActions } from '../redux/tasks/actions';
import { getKeyByValue } from '../common/utils';

const TasksTabs = ({
  match: {
    params: { tasksType },
  },
  tasks,
  pending,
  getTasks,
}) => {
  useEffect(() => {
    getTasks();
  }, [getTasks]);

  if (!tasks) return null;

  const tabIndex = routes.tasks.types.indexOf(`/${tasksType}`);

  const tags = tasks
    .reduce((acc, task) => {
      task.tags.forEach(tag => {
        if (!acc.includes(tag)) {
          acc.push(tag);
        }
      });

      return acc;
    }, [])
    .sort();

  const operators = tasks
    .reduce((acc, task) => {
      const operator = `${task.userFirstName} ${task.userLastName}`;
      if (!acc.includes(operator)) {
        acc.push(operator);
      }

      return acc;
    }, [])
    .sort();

  const details = tasks
    .reduce((acc, task) => {
      if (!acc.includes(task.details)) {
        acc.push(task.details);
      }

      return acc;
    }, [])
    .sort();

  const tasksFilteredByTypes = Object.values(TASKS_TYPES).reduce(
    (acc, type) => ({
      ...acc,
      [getKeyByValue(TASKS_TYPES, type)]: tasks.filter(task => task.taskType === type),
    }),
    {},
  );

  return (
    <Flex w="100%" px={10} pt={5} pb={6} flexGrow={1} direction="column">
      <Flex>
        <PageTitle mb={6}>Tâches à effectuer</PageTitle>
      </Flex>
      <BaseTabs
        defaultIndex={tabIndex >= 0 ? tabIndex + 1 : 0}
        onChange={index => history.push(index ? getTasksPath(index - 1) : routes.tasks.index)}
      >
        <TabList>
          <Tab>Toutes</Tab>

          {Object.entries(TASKS_TYPES).map(([type, label]) => (
            <TabWithBadge
              key={type}
              badgeBgColor={`tag.secondaryBg.${type}`}
              badgeContent={tasksFilteredByTypes[type].length}
            >
              {label}
            </TabWithBadge>
          ))}
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllTasksTab
              tasks={tasks}
              tags={tags}
              operators={operators}
              details={details}
              pending={pending}
            />
          </TabPanel>

          {Object.keys(TASKS_TYPES).map(type => (
            <TabPanel key={type}>
              <FilteredTasksTab
                tasks={tasksFilteredByTypes[type]}
                tags={tags}
                operators={operators}
                details={details}
                pending={pending}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </BaseTabs>
    </Flex>
  );
};

const mapStateToProps = ({ tasks: { data: tasks, pending } }) => ({ tasks, pending });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTasks: tasksActions.getTasks,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TasksTabs);
