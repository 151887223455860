import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box, Flex, Switch } from '@chakra-ui/core';
import { lightenDarkenColor } from '../common/utils';
import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { foldersAPI } from '../common/api/folders';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SelectInput } from '../common/components/Form/FormSelectInput';
import { SecondaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { qualityControlSummariesActions } from '../redux/quality/actions';
import { Pagination } from '../common/components/Pagination';
import Comments from '../common/components/Comments/Comments';
import Can from '../roleBasedAccess/Can';
import { useQualityControlSummariesFilters } from './useQualityControlSummariesFilters.hook';
import { THFilter } from '../tasks/components';

const STATES = ['Actif', 'En instance', 'Cloturé'];
const INDICATORS = ['En attente de visite', 'En attente de CR', 'Difficultés à contacter le bénéficiaire'];

const ConnectedQualityControlSummariesIndex = ({ getQualityControlSummaries, updateQualityControlSummaries, updateQualityControlSummariesComments, pending, qualityControlSummaries, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [
    {
      currentStates,
      currentTags,
      currentIndicators,
      setCurrentStates,
      setCurrentTags,
      setCurrentIndicators,
    },
  ] = useQualityControlSummariesFilters();

  const [search, setSearch] = useState({
    keywords: '',
    ergoUser: '',
    qualityControllerUser: '',
    tags: currentTags,
    status: currentStates,
    indicators: currentIndicators,
  });
  const [tags, setTags] = useState([]);
  const [ergoUsers, setErgoUsers] = useState([]);
  const [qualityControllerUsers, setQualityControllerUsers] = useState([]);


  useEffect(() => {
    getQualityControlSummaries(currentPage, search);
  }, [getQualityControlSummaries, currentPage, search]);

  useEffect(() => {
    setCurrentPage(1);
    setSearch({ ...search, tags: currentTags, status: currentStates, indicators: currentIndicators });
  }, [currentStates, currentTags, currentIndicators]);

  useEffect(() => {
    foldersAPI
      .getTags({ search: '' })
      .then(availableTags => {
        setTags(availableTags);
      })
      .catch(console.log);
  }, [setTags]);

  useEffect(() => {
    if (meta) {
      setErgoUsers(meta.availableErgoUsers.map(user => ({ label: user.fullName, value: user.id })));
    }
  }, [setErgoUsers, meta]);

  useEffect(() => {
    if (meta) {
      setQualityControllerUsers(meta.availableQualityControlUsers.map(user => ({ label: user.fullName, value: user.id })));
    }
  }, [setQualityControllerUsers, meta]);

  

  return (
    <PageIndex
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Header
          meta={meta}
          pending={pending}
          ergoUsers={ergoUsers}
          qualityControllerUsers={qualityControllerUsers}
          onSearch={searchValues => setSearch(prevSearch => ({ ...prevSearch, ...searchValues }))}
        />
      }
    >
      <Table>
        <Thead>
          <THeadRow>
            <TH size="sm" width="16%">
              Bénéficiaire
            </TH>
            <TH size="sm">
              <THFilter
                options={tags}
                label="Tag"
                menuOptionGroupOptions={{ onChange: setCurrentTags, value: currentTags }}
                isUpppercase
                placement="bottom-center"
              />
            </TH>
            <TH size="sm" width="15%">
              Intervenant
            </TH>
            <TH size="sm" width="15%">
              Contrôleur qualité
            </TH>
            <TH size="sm" width="8%">
              <THFilter
                options={STATES}
                label="Statut BAM"
                menuOptionGroupOptions={{ onChange: setCurrentStates, value: currentStates }}
                // placement="bottom-center"
              />
            </TH>
            <TH size="sm" width="10%">
              <THFilter
                options={INDICATORS}  
                label="Indicateur"
                menuOptionGroupOptions={{ onChange: setCurrentIndicators, value: currentIndicators }}
                // placement="bottom-center"
              />
            </TH>
            <TH size="sm" width="10%">
              Délai
            </TH>
            <TH size="sm" width="10%">
              Relance auto.
            </TH>
            <TH size="sm" textAlign="center">
              Actions
            </TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!qualityControlSummaries || pending ? (
            <TablePlaceholder colSpan={10} />
          ) : (
            qualityControlSummaries.map((qualityControlSummary, index) => {
              const {
                id,
                folderId,
                formattedId,
                recipientTitle,
                recipientFirstName,
                recipientLastName,
                ergoUser,
                qualityControllerUser,
                indicator,
                state,
                delay,
                tag,
                qualityControlNotify,
                comments,
              } = qualityControlSummary;

              return (
                <TBodyRow key={index} hasScaleOnHover={false}>
                  <TD>
                    {formattedId} - <span textTransform="capitalize">{recipientTitle}.</span> <span textTransform="uppercase">{recipientLastName}</span> {recipientFirstName}
                  </TD>
                  <TD>
                    <TableEditLink size="sm" textTransform="uppercase">
                      {tag}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm">
                      {ergoUser}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm">
                      {qualityControllerUser}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm">
                      {state}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm">
                      {indicator}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm">
                      {delay}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <Switch
                      name="qualityControlNotify"
                      size="sm"
                      onChange={() => updateQualityControlSummaries({...qualityControlSummary, qualityControlNotify: !qualityControlNotify})}
                      isChecked={qualityControlNotify}
                      css={theme => ({
                        height: '1rem',
                        margin: '0 1ch',
                        '> div': {
                          backgroundColor: lightenDarkenColor(theme.colors.gray2, 15),
                          width: '2.19rem',
                          height: '1rem',
                          padding: 0,
                          div: {
                            width: '1.125rem',
                            height: '1.125rem',
                            backgroundColor: theme.colors.gray2,
                          },
                        },
                        'input[type=checkbox]:checked + div': {
                          backgroundColor: theme.colors.coral[400],
                          div: {
                            backgroundColor: theme.colors.coral[900],
                            transform: 'translateX(calc(2.19rem - 1.125rem))',
                          },
                        },
                        'input[type=checkbox]:indeterminate + div': {
                          div: {
                            transform: 'translateX(calc((2.19rem - 1.125rem) / 2))',
                          },
                        },
                        'input[type=checkbox]:focus + div': { boxShadow: 'none' },
                      })}
                    />
                  </TD>

                  <TD textAlign="center">
                    <Box py={1}>
                      <Can
                        perform="comments:read"
                        yes={() => (
                          <Comments
                            folder={{
                              formattedId,
                              id: folderId,
                              state,
                              recipientFirstName,
                              recipientLastName,
                            }}
                            comments={comments}
                            updateFallback={updatedFolder => updateQualityControlSummariesComments(id, updatedFolder)}
                          />
                        )}
                      />
                    </Box>
                  </TD>
                </TBodyRow>
              );
            })
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ qualityControlSummaries: state }) => {
  return {
    pending: state.pending,
    qualityControlSummaries: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getQualityControlSummaries: qualityControlSummariesActions.getQualityControlSummaries,
      updateQualityControlSummariesComments: qualityControlSummariesActions.updateQualityControlSummariesComments,
      updateQualityControlSummaries: qualityControlSummariesActions.updateQualityControlSummaries,
    },
    dispatch,
  );

export const QualityControlSummariesIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedQualityControlSummariesIndex);

const SearchBar = ({ onSearch, ergoUsers, qualityControllerUsers }) => {
  return (
    <Flex isInline justifyContent="space-between" w="100%">
      <Formik
        initialValues={{
          keywords: '',
          ergoUser: '',
          qualityControllerUser: '',
        }}
        onSubmit={values => onSearch(values)}
      >
        {formik => (
          <Box
            as="form"
            onSubmit={formik.handleSubmit}
            d="flex"
            alignItems="start"
            flexGrow="1"
            minW={0}
            mr={3}
          >
            <Box d="flex" flexDir="column" width="100%" flexGrow={1} minW={0}>
              <SearchInput name="keywords" placeholder="Nom, Prénom, n° de dossier, tag" />
              <Box d="flex" alignItems="start" justifyContent="start" flexWrap="wrap" mt={2}>
                <SelectInput
                  name="ergoUser"
                  placeholder="Filtrer par intervenant"
                  styles={{ flexGrow: 1, maxW: '30%', mr: 3 }}
                  size="sm"
                  options={[
                    { label: 'Filtrer par intervenant', value: '' },
                    ...ergoUsers,
                  ]}
                />
                <SelectInput
                  name="qualityControllerUser"
                  placeholder="Filtrer par contrôleur qualité"
                  styles={{ flexGrow: 1, maxW: '30%', mr: 3 }}
                  css={{
                    '[class*=-indicatorContainer]:first-of-type': {
                      position: 'static',
                    },
                  }}
                  size="sm"
                  options={[
                    { label: 'Filtrer par contrôleur qualité', value: '' },
                    ...qualityControllerUsers,
                  ]}
                />
              </Box>
            </Box>
            <SecondaryButton type="submit" size="sm" ml={3}>
              Lancer la recherche
            </SecondaryButton>
          </Box>
        )}
      </Formik>
    </Flex>
  );
};

const Header = ({ onSearch, meta, pending, ergoUsers, qualityControllerUsers }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} ergoUsers={ergoUsers} qualityControllerUsers={qualityControllerUsers} />
      {(meta || pending) && (
        <TableResults ressource="bénéficiaire" total={meta?.total || 0} style={{ mt: -3 }} />
      )}
    </>
  );
};
