import { qualityControlSummariesConstants } from './constants';
import { qualityControlSummariesAPI } from '../../common/api/qualityControlSummaries';
import { errorsActions } from '../errors/actions';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';

function getQualityControlSummaries(page, search) {
  function request() {
    return { type: qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_REQUEST };
  }
  function success(data) {
    return { type: qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_SUCCESS, data };
  }
  function failure(error) {
    return { type: qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return qualityControlSummariesAPI.getQualityControlSummaries(page, search).then(({ qualityControlSummaries, meta }) => {
      dispatch(success({ qualityControlSummaries, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateQualityControlSummariesComments(qualityControlSummary, folder) {
  return { type: qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_COMMENTS, qualityControlSummary, folder };
}

function updateQualityControlSummaries(qualityControlSummary) {
  function request() {
    return { type: qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_REQUEST };
  }
  function success(data) {
    return { type: qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_SUCCESS, data };
  }
  function failure(error) {
    return { type: qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_FAILURE, error };
  }
  return dispatch => {
    dispatch(request());
    return qualityControlSummariesAPI.editQualityControlSummary(qualityControlSummary).then(({ qualityControlSummary }) => {
      dispatch(success(qualityControlSummary));
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const qualityControlSummariesActions = {
  getQualityControlSummaries,
  updateQualityControlSummariesComments,
  updateQualityControlSummaries,
};
