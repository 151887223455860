import { useState } from 'react';

export const useQualityControlSummariesFilters = () => {
  const [currentTags, setCurrentTags] = useState([]);
  const [currentStates, setCurrentStates] = useState(['Actif']);
  const [currentErgoUser, setCurrentErgoUser] = useState([]);
  const [currentQualityControllerUser, setCurrentQualityControllerUser] = useState([]);
  const [currentIndicators, setCurrentIndicators] = useState([]);

  return [
    {
      currentTags,
      setCurrentTags,
      currentStates,
      setCurrentStates,
      currentErgoUser,
      setCurrentErgoUser,
      currentQualityControllerUser,
      setCurrentQualityControllerUser,
      currentIndicators,
      setCurrentIndicators,
    },
  ];
};
