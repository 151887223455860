import React from 'react';
import { Route, Switch } from 'react-router';

import { QualityControlSummariesIndex } from './QualityControlSummariesIndex';
import { PageError404 } from '../errors/components';

export const QualityControlSummaries = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url} exact component={QualityControlSummariesIndex} />
      <Route component={PageError404} />
    </Switch>
  );
};
