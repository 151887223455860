import { blocAConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import {
  getDateString,
  getDateFromString,
  getStringFromBoolean,
  getBooleanFromString,
} from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  housingVisitedAt,
  sent_2dpeAt: sent2dpeAt,
  updated_2dpeAt: updated2dpeAt,
  controlled_2dpeAt: controlled2dpeAt,
  visitReminderSentAt,
  primary_2dpeFinalizedAt: primary2dpeFinalizedAt,
  recipientContactedAt,
  isRecipientContacted,
  worksQuoteReceived,
  worksQuoteReceivedAt,
  worksQuoteAdditionalRequestsSentAt,
  ...blocA
}) => {
  const workProject = blocA.projects?.find(proj => proj.name === 'works');
  const positionedCompany = workProject?.positionedCompany;
  return {
    ...blocA,
    ergoUserId: blocA.ergoUserId
      ? { label: blocA.folder.operators.ergo, value: blocA.ergoUserId }
      : null,
    qualityControllerUserId: blocA.qualityControllerUserId
      ? {
          label: blocA.folder.operators.qualityController,
          value: blocA.qualityControllerUserId,
        }
      : null,
    positionedCompany: positionedCompany
      ? { label: positionedCompany.name, value: positionedCompany.companyId }
      : null,
    isPublished: workProject?.isPublishedInProjectPlace,
    housingVisitedAt: getDateFromString(housingVisitedAt),
    primary_2dpeFinalizedAt: getDateFromString(primary2dpeFinalizedAt),
    recipientContactedAt: getDateFromString(recipientContactedAt),
    sent_2dpeAt: getDateFromString(sent2dpeAt),
    updated_2dpeAt: getDateFromString(updated2dpeAt),
    controlled_2dpeAt: getDateFromString(controlled2dpeAt),
    visitReminderSentAt: getDateFromString(visitReminderSentAt),
    isRecipientContacted: getStringFromBoolean(isRecipientContacted),
    worksQuoteReceived: getStringFromBoolean(
      Boolean(blocA.firstQuoteVersion?.downloadLink) || worksQuoteReceived,
    ),
    worksQuoteReceivedAt: getDateFromString(worksQuoteReceivedAt),
    worksQuoteAdditionalRequestsSentAt: getDateFromString(worksQuoteAdditionalRequestsSentAt),
    ...Object.entries(blocA.diagnosticSummary).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          typeof value.answers === 'boolean' && value.type === 'simple_choice_radio'
            ? getStringFromBoolean(value.answers)
            : value.answers,
      }),
      {},
    ),
  };
};

function getBlocA(folderId) {
  function request() {
    return { type: blocAConstants.GET_BLOCA_REQUEST };
  }
  function success(blocA) {
    return { type: blocAConstants.GET_BLOCA_SUCCESS, blocA };
  }
  function failure(error) {
    return { type: blocAConstants.GET_BLOCA_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_a_ergo_diagnostic')
      .then(({ blocAErgoDiagnostic: blocA }) => {
        dispatch(success(transformApiPayloadToFormValues(blocA)));
        dispatch(foldersActions.getFolderFromBloc(blocA.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocA(folderId, updatedBlocA) {
  function request(blocA) {
    return { type: blocAConstants.UPDATE_BLOCA_REQUEST, blocA };
  }
  function success(blocA) {
    return { type: blocAConstants.UPDATE_BLOCA_SUCCESS, blocA };
  }
  function failure(error) {
    return { type: blocAConstants.UPDATE_BLOCA_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocA));

    const worksProject = updatedBlocA.projects?.find(proj => proj.name === 'works');
    console.log(updatedBlocA);
    return blocAPI
      .updateBloc(folderId, 'bloc_a_ergo_diagnostic', {
        ...updatedBlocA,
        housingVisitedAt: getDateString(updatedBlocA.housingVisitedAt),
        primary_2dpeFinalizedAt: getDateString(updatedBlocA.primary_2dpeFinalizedAt),
        recipientContactedAt: getDateString(updatedBlocA.recipientContactedAt),
        sent_2dpeAt: getDateString(updatedBlocA.sent_2dpeAt),
        controlled_2dpeAt: getDateString(updatedBlocA.controlled_2dpeAt),
        updated_2dpeAt: getDateString(updatedBlocA.updated_2dpeAt),
        visitReminderSentAt: getDateString(updatedBlocA.visitReminderSentAt),
        isRecipientContacted: getBooleanFromString(updatedBlocA.isRecipientContacted),
        worksQuoteReceived: getBooleanFromString(updatedBlocA.worksQuoteReceived),
        worksQuoteReceivedAt: getDateString(updatedBlocA.worksQuoteReceivedAt),
        worksQuoteAdditionalRequestsSentAt: getDateString(
          updatedBlocA.worksQuoteAdditionalRequestsSentAt,
        ),
        ergoUserId: updatedBlocA.ergoUserId?.value || null,
        qualityControllerUserId: updatedBlocA.qualityControllerUserId?.value || null,
        folderAttributes: {
          id: updatedBlocA.folderId,
          isPublishedOnErgoProjectPlace: updatedBlocA.isPublishedOnErgoProjectPlace,
          ergoInterventionType: updatedBlocA.ergoInterventionType,
          // eslint-disable-next-line no-nested-ternary
          projectsAttributes: updatedBlocA.positionedCompany
            ? [
                {
                  id: worksProject?.id,
                  companyIds: updatedBlocA.positionedCompany
                    ? [updatedBlocA.positionedCompany.value]
                    : null,
                  name: 'works',
                },
              ]
            : updatedBlocA.typeOfVisit === 'joint' &&
              !updatedBlocA.positionedCompany &&
              !updatedBlocA.projects.length
            ? [
                {
                  name: 'works',
                  isPublished: updateBlocA.isPublished,
                },
              ]
            : null,
        },
      })
      .then(({ blocAErgoDiagnostic: blocA }) => {
        dispatch(foldersActions.getFolderFromBloc(blocA.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocA)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBlocAById = (state, props) => state.blocs.blocA[props.match.params.folderId];

export const blocASelectors = {
  getBlocAById,
};

export const blocAActions = {
  getBlocA,
  updateBlocA,
};
