import React, { useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { BaseTabs, TabList, Tab, TabPanels, TabPanel } from '../common/components/Tabs';
import routes, { getSectorsPath } from '../common/routes';
import { history } from '../common/helpers/history';
import { PageTitle } from '../common/components/Titles';
import { departmentsActions } from '../redux/departments/actions';
import { SelectInput } from '../common/components/Form/FormSelectInput';
import { usersAPI } from '../common/api/users';
import { Form, FormActions, FormSection } from '../common/components/Form/Form';
import { PrimaryButton } from '../common/components/Buttons';

const SectorsTabs = ({
  match: {
    params: { sectorsType },
  },
  departments,
  getDepartments,
  updateDepartment,
}) => {
  const tabIndex = routes.sectors.types.indexOf(`/${sectorsType}`);

  const [availableErgos, setAvailableErgos] = useState([]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    usersAPI
      .getUsers(0, '', { perPage: 1000, byRoles: ['ergothérapeute'] })
      .then(({ users: operators }) => {
        setAvailableErgos(
          operators
            .filter(operator => operator.ergoQualification === 'ergothérapeute interne')
            .map(operator => ({
              label: operator.fullName,
              value: operator.id,
              role: operator.role,
            })),
        );
      })
      .catch(console.log);
  }, [setAvailableErgos]);

  const departmentsByRegion = departments.reduce((acc, department) => {
    const { region } = department;
    if (!acc[region]) {
      acc[region] = [];
    }
    acc[region].push(department);
    return acc;
  }, {});

  const sortedRegions = Object.keys(departmentsByRegion).sort();

  // Sort departments by name within each region
  Object.keys(departmentsByRegion).forEach(region => {
    departmentsByRegion[region].sort((a, b) => a.name.localeCompare(b.name));
  });

  const onSubmit = (values, { setSubmitting, setErrors, resetForm }) => {
    updateDepartment(values.id, values);
    resetForm({ values }); // Reset the form to initial values after submission
  };

  if (!departments) return null;

  return (
    <Flex w="100%" px={10} pt={5} pb={6} flexGrow={1} direction="column">
      <Flex>
        <PageTitle mb={6}>Secteurs</PageTitle>
      </Flex>
      <BaseTabs
        defaultIndex={tabIndex >= 0 ? tabIndex + 1 : 0}
        onChange={index =>
          history.push(index ? getSectorsPath(index - 1) : routes.departments.index)
        }
      >
        <TabList>
          <Tab>Contrôle qualité</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={8}>
            {sortedRegions.map(region => (
              <Box key={region} mb={6}>
                <Box color="coral.900" fontWeight="bold" mb={6} textTransform="uppercase">
                  {region}
                </Box>
                {departmentsByRegion[region].map(department => (
                  <Formik
                    enableReinitialize
                    initialValues={department}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                      return onSubmit(values, actions);
                    }}
                  >
                    {formik => (
                      <Form pt={0} pb={0} mx={0} onSubmit={formik.handleSubmit}>
                        <Box
                          key={department.id}
                          mb={2}
                          d="grid"
                          gridColumnGap={6}
                          gridTemplateColumns="12rem 14rem 7rem"
                        >
                          <Box fontWeight="bold">
                            {department.code} - {department.name}
                          </Box>
                          <SelectInput
                            styles={{ marginBottom: 0 }}
                            name="qualityControllerUserId"
                            isClearable
                            options={availableErgos}
                            placeholder="Référent"
                            noOptionsMessage={() => 'Aucun ergothérapeute disponible'}
                          />
                          <FormActions mt={0}>
                            {formik.dirty && (
                              <PrimaryButton type="submit">Enregistrer</PrimaryButton>
                            )}
                          </FormActions>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                ))}
              </Box>
            ))}
          </TabPanel>
        </TabPanels>
      </BaseTabs>
    </Flex>
  );
};

const mapStateToProps = ({ departments: { data: departments, pending } }) => ({
  departments,
  pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDepartments: departmentsActions.getDepartments,
      updateDepartment: departmentsActions.updateDepartment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SectorsTabs);
