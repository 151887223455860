import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { departmentsConstants } from './constants';
import { departmentsAPI } from '../../common/api/departments';
import { errorsActions } from '../errors/actions';

export const transformApiPayloadToFormValues = ({
  qualityControllerUserId,
  qualityControllerUser,
  ...department
}) => ({
  ...department,
  qualityControllerUserId: qualityControllerUserId
    ? { label: qualityControllerUser.fullName, value: qualityControllerUser.id }
    : null,
});

export const transformApiPayloadArrayToFormValues = (
  departments
) => (
  departments.map(transformApiPayloadToFormValues)
);

function getDepartments() {
  function request() {
    return { type: departmentsConstants.GET_DEPARTMENTS_REQUEST };
  }
  function success(departments) {
    return { type: departmentsConstants.GET_DEPARTMENTS_SUCCESS, departments };
  }
  function failure(error) {
    return { type: departmentsConstants.GET_DEPARTMENTS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return departmentsAPI.getDepartments().then(({ departments }) => {
      dispatch(
        success(transformApiPayloadArrayToFormValues(departments)),
      );
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateDepartment(departmentId, updatedDepartment) {
  function request(department) {
    return { type: departmentsConstants.UPDATE_DEPARTMENT_REQUEST, department };
  }
  function success(department) {
    return { type: departmentsConstants.UPDATE_DEPARTMENT_SUCCESS, department };
  }
  function failure(error) {
    return { type: departmentsConstants.UPDATE_DEPARTMENT_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedDepartment));

    return departmentsAPI.updateDepartment(departmentId, {
      ...updatedDepartment,
      qualityControllerUserId: updatedDepartment.qualityControllerUserId?.value || null,
    }).then(( department ) => {
      dispatch(success(transformApiPayloadToFormValues(department)));
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure)));
  }
}

export const departmentsActions = {
  getDepartments,
  updateDepartment
};
