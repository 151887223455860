export const qualityControlSummariesConstants = {
  GET_QUALITY_CONTROL_SUMMARIES_REQUEST: 'GET_QUALITY_CONTROL_SUMMARIES_REQUEST',
  GET_QUALITY_CONTROL_SUMMARIES_SUCCESS: 'GET_QUALITY_CONTROL_SUMMARIES_SUCCESS',
  GET_QUALITY_CONTROL_SUMMARIES_FAILURE: 'GET_QUALITY_CONTROL_SUMMARIES_FAILURE',
  UPDATE_QUALITY_CONTROL_SUMMARIES_COMMENTS: 'UPDATE_QUALITY_CONTROL_SUMMARIES_COMMENTS',
  UPDATE_QUALITY_CONTROL_SUMMARIES: 'UPDATE_QUALITY_CONTROL_SUMMARIES',
  UPDATE_QUALITY_CONTROL_SUMMARIES_REQUEST: 'UPDATE_QUALITY_CONTROL_SUMMARIES_REQUEST',
  UPDATE_QUALITY_CONTROL_SUMMARIES_SUCCESS: 'UPDATE_QUALITY_CONTROL_SUMMARIES_SUCCESS',
  UPDATE_QUALITY_CONTROL_SUMMARIES_FAILURE: 'UPDATE_QUALITY_CONTROL_SUMMARIES_FAILURE',
};
