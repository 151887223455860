import { qualityControlSummariesConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
};

export function qualityControlSummaries(state = initialState, action) {
  switch (action.type) {
    case qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_SUCCESS:
      return {
        ...state,
        data: action.data.qualityControlSummaries,
        meta: action.data.meta,
        pending: false,
      };
    case qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_COMMENTS:
      return {
        ...state,
        data: state.data.map(qualityControlSummary => {
          if (qualityControlSummary.id === action.qualityControlSummary) {
            return {
              ...qualityControlSummary,
              comments: action.folder.comments,
            };
          }
          return qualityControlSummary;
        }),
      };
    case qualityControlSummariesConstants.UPDATE_QUALITY_CONTROL_SUMMARIES_SUCCESS: {
      const qualityControlSummaryToUpdateIndex = state.data.findIndex(qualityControlSummary => qualityControlSummary.id === action.data.id);

      const data = [...state.data];

      data[qualityControlSummaryToUpdateIndex] = { ...data[qualityControlSummaryToUpdateIndex], ...action.data };

      return {
        ...state,
        data,
      };
    }
    case qualityControlSummariesConstants.GET_QUALITY_CONTROL_SUMMARIES_FAILURE:
    default:
      return state;
  }
}
